<template>
  <div class="summary">
    <!-- 左侧菜单 -->
    <SummaryMenu @changeMenu="changeMenu" />
    <!-- 右侧内容 -->
    <div class="summary-right">
      <!-- 新增总结 -->
      <SummaryAdd :isClear="isClear" @summarySend="summarySend" />
      <!-- 搜索项 -->
      <SummarySearch @search="search" @handleExport="handleExport" />
      <!-- 列表 -->
      <SummaryList :list="list" :loading="loading" :hasData="page.total>0" :noMore="noMore" @loadMore="loadMore" />
    </div>
    <!-- 导出弹窗 -->
    <ExportModal :propsObj="propsObjInData"></ExportModal>
  </div>
</template>
<script>
import SummaryMenu from './components/summary_menu.vue';
import SummaryAdd from './components/summary_add.vue';
import SummarySearch from './components/summary_search.vue';
import SummaryList from './components/summary_list.vue';
import request from '../../../../../utils/request';
import ExportModal from '../../../../../components/export_modal';
import * as utils from '../../../../../utils/index';

export default {
  name: 'index',
  components: {
    SummaryMenu,
    SummaryAdd,
    SummarySearch,
    SummaryList,
    ExportModal,
  },
  data() {
    return {
      isClear: false, // 是否清空输入框
      list: [], // 列表数据
      params: {}, // 数据查询
      loading: false,
      noMore: true,
      page: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      },
      propsObjInData: {},
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** @desc 左侧菜单切换 */
    changeMenu(val) {
      this.params.queryType = val.code;
      this.list = [];
      this.page.pageNum = 1;
      this.getList();
    },
    /** @desc 点击总结发送按钮 */
    summarySend(val) {
      const params = val;
      request.post('/sfa/sfa-work-summary-primary-web/save', params).then((res) => {
        if (!res.success) return false;
        this.$message.success('发送成功！');
        this.isClear = true;
        this.getList();
      });
    },
    /** @desc 导出按钮 */
    handleExport() {
      const sid = utils.getUuid(32, 16);
      const params = {
        sid,
        requestUrl: '/sfa/sfa-work-summary-primary-web/export',
        functionCode: 'sfa_work_summary_primary_export',
        parentCode: this.$store.state.menus.selectMenu,
      };
      request.post('/excel/excelController/repeatedWriteExport', params).then((res) => {
        if (!res.success) return false;
        this.propsObjInData = {
          functionCode: params.functionCode,
          requestUrl: params.requestUrl,
          visible: true,
          exportMessage: '',
          isExport: true,
          formData: {},
          sid,
        };
      });
    },
    /** @desc 查询列表 */
    search(val) {
      if (val) {
        this.params = val;
        this.params.startTime = val.date && val.date.length > 0 ? val.date[0] : null;
        this.params.endTime = val.date && val.date.length > 0 ? val.date[1] : null;
        delete this.params.date;
      }
      this.list = [];
      this.page.pageNum = 1;
      this.getList();
    },
    /** @desc 加载更多 */
    loadMore() {
      if (this.noMore) return false;
      this.loading = true;
      this.page.pageNum += 1;
      this.getList();
    },
    /** @desc 获取列表 */
    getList() {
      const params = {
        ...this.params,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true;
      request.post('/sfa/sfa-work-summary-primary-web/list', params).then((res) => {
        if (!res.success) return false;
        const { result } = res;
        const data = result.data || [];
        if (data && data.length > 0) {
          data.map((v) => {
            const item = v;
            item.images = [];
            if (v.pictureRespVos && v.pictureRespVos.length > 0) {
              v.pictureRespVos.map((m) => {
                item.images.push(m.urlPathPrefix + m.urlPath);
                return m;
              });
            }
            return item;
          });
        }
        this.page.total = result.count;
        this.list = this.list.concat(data);
        this.noMore = this.page.pageNum * this.page.pageSize > this.page.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="less" scoped>
  .summary{
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
    height: 100%;
    box-sizing: border-box;
    .summary-right{
      flex: 1;
      padding-left: 15px;
      padding-right: 10px;
      height: 100%;
      box-sizing: border-box;
      overflow-y: scroll;
    }
    // 滑动条样式
    .summary-right::-webkit-scrollbar {
      /*滚动条整体样式*/
      background-color: #FFFFFF;
      width: 8px;
      height: 8px
    }
    .summary-right::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      background-color: rgba(221, 222, 224, 0.8);
      border-radius: 5px;
      border: 1px solid #f1f1f1;
      box-shadow: inset 0 0 6px rgb(0 0 0 / 60%);
    }
    .summary-right::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      background: #FFFFFF;
    }
  }
</style>
