<template>
  <!-- 新增总结 -->
  <div class="summary-add">

    <el-tabs class="summary-tabs" v-model="activeName" @tab-click="tabsClick">
      <template v-for="(item, index) in tabsList">
        <el-tab-pane :label="item.text" :name="item.name" :key="index"></el-tab-pane>
      </template>
    </el-tabs>
    <div class="textarea is_required">
      <ContentEdit :isClear="isClear" :placeholder="tabsList[tIndex].placeholder1" :selectPeoples="showSelectPeoples" :addText="addText" @getvalue="(val)=>getvalue(val, 'summary')"></ContentEdit>
    </div>
    <div class="textarea" v-if="activeName!=='EXPERIENCE'">
      <ContentEdit :isClear="isClear" :placeholder="tabsList[tIndex].placeholder2" @getvalue="(val)=>getvalue(val, 'plan')"></ContentEdit>
    </div>
    <!-- <el-input class="textarea is_required" type="textarea" :placeholder="tabsList[tIndex].placeholder1" v-model="summary" :autosize="{ minRows: 4 }"></el-input> -->
    <!-- <el-input class="textarea" type="textarea" :placeholder="tabsList[tIndex].placeholder2" v-model="plan" :autosize="{ minRows: 4 }" v-if="activeName!=='EXPERIENCE'"></el-input> -->
    <div class="images-container">
      <div class="images-item" v-for="(item, index) in fileList" :key="index" @mouseover="mouseOverOrLeave(index, false)" @mouseleave="mouseOverOrLeave(index, true)">
        <el-image class="images" :src="item.url"></el-image>
        <div class="images-shadow" v-if="item.showShadow">
          <i class="el-icon-zoom-in" @click="previewImage(item.url)"></i>
          <i class="el-icon-delete" @click="delImage(index)"></i>
        </div>
      </div>
    </div>
    <!-- <div class="custom-people" v-if="selectValue==='COLLEAGUE'">
      <div>可见范围：</div>
      <div class="custom-people-list">
        <div class="custom-people-item" v-for="(item, index) in customPeoples" :key="index">{{item.fullName}}</div>
        <el-button type="text" @click="showSelectModal(true)">继续添加</el-button>
      </div>
    </div> -->
    <div class="summary-add-button">
      <div class="summary-add-button-left">
        <i @click="showSelectModal(false)">@</i>
        <el-upload class="upload-demo"
               action="/upload/uploadController/uploadOssList"
               :headers="{ loginUserToken: Storage.l.get('TokenKey') }"
               list-type="picture"
               :show-file-list="false"
               accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PNG,.GIF"
               :limit="limit"
               :file-list="fileList"
               :before-upload="beforeUpload"
               :on-progress="handleProgress"
               :on-success="handleSuccess"
               :on-exceed="handleExceed">
          <i class="el-icon-picture-outline"></i>
        </el-upload>
        <template v-if="tabsList[tIndex].key">
          <el-button type="primary" @click="getVisitNum"><i class="el-icon-plus"></i>{{tabsList[tIndex].key}}拜访数</el-button>
          <el-button type="primary" @click="getOrderNum"><i class="el-icon-plus"></i>{{tabsList[tIndex].key}}订单数</el-button>
          <el-button type="primary" @click="getCustomerNum"><i class="el-icon-plus"></i>{{tabsList[tIndex].key}}新增客户数</el-button>
        </template>
      </div>
      <div class="summary-add-button-right">
        <!-- <el-select v-model="selectValue" placeholder="请选择" @change="selectChange">
          <el-option v-for="item in options" :key="item.dictCode" :label="item.dictValue" :value="item.dictCode"></el-option>
        </el-select> -->
        <el-button class="summary-add-button-btn" type="primary" @click="send">发送</el-button>
      </div>
    </div>
    <!-- 选择人员弹窗 -->
    <SelectPeople :showModal="showModal" @closeSelectModal="closeSelectModal" :selectLists="selectList" @getSelectPeople="getSelectPeople" />
    <!-- 图片预览 -->
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogPreviewImage" alt="">
    </el-dialog>
  </div>
</template>
<script>
import Storage from '../../../../../../utils/storage';
import * as utils from '../../../../../../utils/index';
import request from '../../../../../../utils/request';
import ContentEdit from './content_edit.vue';
import SelectPeople from './select_people.vue';

const appKey = process.env.VUE_APP_AMAP_KEY;
export default {
  name: 'summary_add',
  components: {
    ContentEdit,
    SelectPeople,
  },
  props: {

  },
  data() {
    return {
      Storage,
      // 顶部切换
      tabsList: [
        {
          text: '日报', name: 'DAILY', placeholder1: '今日工作总结', placeholder2: '明日工作计划', key: '本日', type: '1',
        },
        {
          text: '周报', name: 'WEEKLY', placeholder1: '本周工作总结', placeholder2: '下周工作计划', key: '本周', type: '2',
        },
        {
          text: '月报', name: 'MONTHLY', placeholder1: '本月工作总结', placeholder2: '下月工作计划', key: '本月', type: '3',
        },
        { text: '心得', name: 'EXPERIENCE', placeholder1: '心得' },
      ],
      activeName: 'DAILY',
      tIndex: 0,
      summary: '', // 总结
      plan: '', // 计划
      isClear: false, // 是否清除输入框内容
      selectValue: 'ALL',
      options: [],
      showModal: false, // 是否显示选择人员弹窗
      selectList: [],
      selectPeoples: [],
      showSelectPeoples: [],
      fileList: [],
      limit: 9,
      dialogVisible: false, // 预览图片弹窗
      dialogPreviewImage: '', // 预览的图片
      addText: '',
      location: {
        address: '',
        lat: '',
        lng: '',
      }, // 地理位置
      isCustom: false, // 是否为自定义
      customPeoples: [], // 自定义选择人员数据
    };
  },
  async created() {
    console.log(process.env);
    this.getDictCode('work_summary_scope');
    await this.syncLoadAMap();
    this.getLocation();
  },
  methods: {
    /** @desc  select框改变 */
    selectChange(val) {
      console.log(val);
      if (val === 'COLLEAGUE') {
        this.isCustom = true;
        this.showModal = true;
        this.selectList = this.customPeoples;
      } else {
        this.isCustom = false;
      }
    },
    /** @desc  获取定位 */
    getLocation() {
      const that = this;
      if (!this.AMap) return false;
      this.AMap.plugin('AMap.Geolocation', () => {
        const geolocation = new this.AMap.Geolocation({
          // 使用web浏览器进行精准定位，并返回定位结果；
          // 注：下面的配置信息，可以去除谷歌定位默认的定位小圆圈；
          enableHighAccuracy: true, // 是否使用高精度定位，默认:true
          timeout: 10000, // 超过10秒后停止定位，默认：无穷大
        });
        geolocation.getCurrentPosition((status, result) => {
          if (status === 'complete') {
            console.log('====result====', result); // result为精准定位成功的返回结果；
            that.location = {
              address: result.formattedAddress,
              lat: result.position.lat,
              lng: result.position.lng,
            };
          } else {
            console.log('=====error result ====', result);// 如果status不等于'complete'，则定位失败；返回的result中包含失败信息；
          }
        });
        // 可代替上方getCurrentPosition
        // geolocation.getCurrentPosition();
        // AMap.event.addListener(geolocation, 'complete', onComplete);//返回定位信息
        // AMap.event.addListener(geolocation, 'error', onError);      //返回定位出错信息
      });
    },
    /** @desc  异步加载高德地图 */
    syncLoadAMap() {
      return new Promise((resolve, reject) => {
        if (window.AMap) {
          this.AMap = window.AMap;
          resolve(window.AMap);
        } else {
          const scriptMap = document.createElement('script');
          scriptMap.type = 'text/javascript';
          scriptMap.async = true;
          scriptMap.src = `https://webapi.amap.com/maps?v=1.3&key=${appKey}`;
          scriptMap.onerror = reject;
          scriptMap.dataTitle = 'AMap';
          // document.body.appendChild(scriptMap);
          const script = document.getElementsByTagName('script')[0];
          script.parentNode.insertBefore(scriptMap, script);
          if (scriptMap.readyState) { // IE
            scriptMap.onreadystatechange = () => {
              if (scriptMap.readyState === 'loaded' || scriptMap.readyState === 'complete') {
                scriptMap.onreadystatechange = null;
                this.AMap = window.AMap;
                resolve(window.AMap);
              }
            };
          } else { // 其他浏览器
            scriptMap.onload = () => {
              this.AMap = window.AMap;
              resolve(window.AMap);
            };
          }
        }
        this.getLocation();
      });
    },
    /** @desc 获取数据字典数据 */
    getDictCode(dictCode) {
      request.post('/mdm/mdmdictdata/list', { dictTypeCode: dictCode }).then((res) => {
        this.options = res.result || [];
      });
    },
    /** @desc 点击切换顶部类型 */
    tabsClick(tab, event) {
      this.activeName = tab.name;
      this.tIndex = Number(tab.index);
      this.summary = '';
      this.plan = '';
      this.isClear = true;
    },
    /** @desc 显示选择人员弹窗 */
    showSelectModal(val) {
      this.isCustom = val || false;
      this.selectList = val ? this.customPeoples : [];
      this.showModal = true;
    },
    /** @desc 关闭选择人员弹窗 */
    closeSelectModal() {
      this.showModal = false;
    },
    /** @desc 关闭选择人员弹窗提交按钮 */
    getSelectPeople(val) {
      if (this.isCustom) {
        this.customPeoples = val;
      } else {
        this.showSelectPeoples = val;
        this.selectPeoples = utils.uniqueArr(this.selectPeoples, val, 'userName');
      }
      this.closeSelectModal();
      console.log(this.customPeoples);
    },
    /** @desc 获取最终的@ 列表 */
    pick(str) {
      const reg = /@([^\s]*?)\s+/g;
      const list = str.match(reg) || [];
      const newList = [];
      list.map((item) => {
        let v = item;
        v = v.substr(1);
        v = v.substr(0, v.length - 1);
        this.selectPeoples.map((m) => {
          if (m.fullName === v) {
            newList.push(m);
          }
          return m;
        });
        return v;
      });
      return newList;
    },
    /** @desc 获取输入框的值 */
    getvalue(val, key) {
      this[key] = val;
      this.isClear = false;
    },
    /** @desc 上传文件之前 */
    beforeUpload(file) {
      console.log('beforeUpload: file======', file);
    },
    /** @desc 文件上传时 */
    handleProgress(e, file, fileList) {
      console.log('handleProgress: e======', e);
      console.log('handleProgress: file======', file);
      console.log('handleProgress: fileList======', fileList);
    },
    /** @desc 文件上传成功 */
    handleSuccess(res, file, fileList) {
      if (res.code === 200 && res.success) {
        res.result.forEach((v) => {
          this.fileList.push(v);
        });
      }
    },
    /** @desc 文件超出个数限制时 */
    handleExceed(file, fileList) {
      console.log('handleExceed: file======', file);
      console.log('handleExceed: fileList======', fileList);
    },
    /** @desc 鼠标移进移出 */
    mouseOverOrLeave(index, val) {
      this.$set(this.fileList[index], 'showShadow', !val);
    },
    /** @desc 删除图片 */
    delImage(index) {
      this.fileList.splice(index, 1);
    },
    /** @desc 放大预览图片 */
    previewImage(url) {
      this.dialogPreviewImage = url;
      this.dialogVisible = true;
    },
    /** @desc 获取拜访数 */
    getVisitNum() {
      request.get('/sfa/sfaVisitTempPlanController/getVisitNum', { dateFlag: this.tabsList[this.tIndex].type }).then((res) => {
        if (!res.success) return false;
        this.addText = `${this.tabsList[this.tIndex].key}拜访数：${res.result}；`;
      });
    },
    /** @desc 获取订单数 */
    getOrderNum() {
      request.get('/sfa/sfaWorkDailyController/getOrderNum', { dateFlag: this.tabsList[this.tIndex].type }).then((res) => {
        if (!res.success) return false;
        this.addText = `${this.tabsList[this.tIndex].key}订单数：${res.result}；`;
      });
    },
    /** @desc 获取客户数 */
    getCustomerNum() {
      request.get('/sfa/client/getCustomerNum', { dateFlag: this.tabsList[this.tIndex].type }).then((res) => {
        if (!res.success) return false;
        this.addText = `${this.tabsList[this.tIndex].key}新增客户数：${res.result}；`;
      });
    },
    /** @desc 点击发送按钮 */
    send() {
      if (!this.summary) {
        this.$message.warning(`请填写${this.tabsList[this.tIndex].placeholder1}`);
        return false;
      }
      const params = {
        lectureType: this.activeName,
        summary: this.summary,
        plan: this.plan,
        atColleagueCode: [],
        pictureReqVos: this.fileList,
        scopeType: this.selectValue,
        isForward: 'N',
        location: this.location.address,
      };
      const list = this.pick(this.summary);
      params.atColleagueCode = list.map((v) => (v.userName));
      params.scopeColleagueCode = this.selectValue === 'COLLEAGUE' ? this.customPeoples.map((v) => (v.userName)) : null;
      request.post('/sfa/sfa-work-summary-primary-web/save', params).then((res) => {
        if (!res.success) return false;
        this.$message.success('发送成功！');
        this.fileList = [];
        this.isClear = true;
        this.$parent.search();
        // this.$emit('summarySend', params);
      });
    },
  },
};
</script>
<style lang="less" scoped>
  /deep/ .el-tabs__nav-wrap::after{
    background-color: inherit;
  }
  /deep/ .el-tabs__item{
    font-weight: normal;
  }
  /deep/ .blue{
    color: #1890ff !important;
  }
  .is_required:before {
    content: '*';
    color: #F56C6C;
    margin-right: 4px;
    display: inline-block;
    position: absolute;
    top: 0;
    left: -8px;
  }
  // 添加
  .summary-add{
    padding: 0 15px;
    background-color: #FFFFFF;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    font-size: 14px;
    .textarea{
      width: 100%;
      height: 100px;
      margin-bottom: 10px;
      position: relative;
    }
    .images-container{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .images-item{
        width: 80px;
        height: 80px;
        margin: 0 10px 10px 0;
        position: relative;
      }
      .images{
        width: 100%;
        height: 100%;
      }
      .images-shadow{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        i{
          font-size: 20px;
          color: #FFFFFF;
          padding: 5px;
          cursor: pointer;
        }
      }
    }
    .custom-people{
      display: flex;
      line-height: 32px;
      &-list{
        display: flex;
        flex-wrap: wrap;
      }
      &-item{
        padding: 0 5px;
        position: relative;
        .el-icon-circle-close{
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    .summary-add-button{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      &-left{
        display: flex;
        align-items: center;
        i{
          font-size: 24px;
          color: #999999;
          padding-right: 20px;
        }
        .el-icon-plus{
          font-size: 12px;
          color: #FFFFFF;
          padding-right: 4px;
        }
      }
      &-btn{
        margin-left: 20px;
      }
    }
  }
</style>
