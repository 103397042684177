<template>
  <!-- 搜索项 -->
  <div class="summary-search">
    <el-row :gutter="10">
      <el-col :span="5">
        <el-select v-model="search.lectureType" placeholder="请选择总结类型" style="width: 100%;">
          <el-option v-for="item in options" :key="item.dictCode" :label="item.dictValue" :value="item.dictCode"></el-option>
        </el-select>
      </el-col>
      <el-col :span="5">
        <el-input v-model="search.searchText" placeholder="请输入提交人姓名" style="width: 100%;"></el-input>
      </el-col>
      <el-col :span="8">
        <el-date-picker v-model="search.date" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 100%;"></el-date-picker>
      </el-col>
      <el-col :span="6" class="summary-search-btn">
        <el-button type="primary" @click="query">查询</el-button>
        <el-button @click="reset">重置</el-button>
        <el-button @click="handleExport">导出</el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import request from '../../../../../../utils/request';

export default {
  name: 'summary_search',
  data() {
    return {
      // 搜索字段
      search: {},
      options: [],
    };
  },
  created() {
    this.getDictCode('lecture_type');
  },
  methods: {
    /** @desc 获取数据字典数据 */
    getDictCode(dictCode) {
      request.post('/mdm/mdmdictdata/list', { dictTypeCode: dictCode }).then((res) => {
        this.options = res.result || [];
      });
    },
    /** @desc 点击查询按钮 */
    query() {
      this.$emit('search', this.search);
      // this.$parent.getList(this.search);
    },
    /** @desc 点击重置按钮 */
    reset() {
      this.search = {};
      this.$emit('search', this.search);
    },
    /** @desc 点击导出 */
    handleExport() {
      this.$emit('handleExport');
    },
  },
};
</script>
<style lang="less" scoped>
  /deep/ .el-date-editor .el-range-separator{
    width: 24px !important;
  }
  // 搜索
  .summary-search{
    padding: 15px;
    margin: 15px 0;
    background-color: #FFFFFF;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    .summary-search-btn{
      text-align: right;
    }
  }
</style>
