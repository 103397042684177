<template>
  <div class="select-people">
    <Modal :modalConfig="modalConfig" @onClose="dialog_btnClose">
      <el-row>
        <el-col :span="8">
          <el-select
            v-model="searchData"
            filterable
            remote
            reserve-keyword
            placeholder="请输入人员姓名模糊搜索"
            :remote-method="remoteMethod"
            @change="getChange($event)"
            @clear="getClear"
            :loading="loading"
            clearable>
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="(item.orgName || '') + '/' + (item.positionName || '') + '/' + (item.fullName || '')"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <div class="table-body">
        <vxe-table border ref="xTable" :data="list" :loading="tableLoading" @checkbox-change="selectEvent" @checkbox-all="selectEvent">
          <vxe-table-column type="checkbox" width="50"></vxe-table-column>
          <vxe-table-column field="userName" title="人员编码" min-width="100" show-overflow></vxe-table-column>
          <vxe-table-column field="fullName" title="人员姓名" min-width="100" show-overflow></vxe-table-column>
          <vxe-table-column title="操作" min-width="100">
            <template v-slot="{rowIndex}">
              <el-button type="text" @click="delTable(rowIndex)">删除</el-button>
            </template>
          </vxe-table-column>
        </vxe-table>
      </div>
      <div class="dialog-footer">
        <el-button type="primary" @click="dialog_btnSubmit" size="small" icon="el-icon-check" >提交</el-button>
        <el-button type="danger"  @click="dialog_btnClose" size="small" icon="el-icon-close" >关闭</el-button>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from '../../../../../../components/modal';
import request from '../../../../../../utils/request';
import Storage from '../../../../../../utils/storage';

export default {
  name: 'select_people',
  components: {
    Modal,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    selectLists: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    showModal: {
      deep: true,
      immediate: true,
      handler(val) {
        this.modalConfig.visible = val;
        if (val) {
          this.searchData = null;
          this.list = [];
          this.selectList = [];
          this.remoteMethod();
        }
      },
    },
    selectLists: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && Array.isArray(val)) {
          this.list = val || [];
        } else {
          this.list = [];
        }
      },
    },
  },
  data() {
    return {
      modalConfig: {
        title: '选择人员',
        visible: false,
        type: 'Modal',
        width: '50%',
      },
      loading: false,
      tableLoading: false,
      options: [],
      searchData: null,
      list: [],
      selectList: [],
    };
  },
  created() {

  },
  methods: {
    /** @desc 人员姓名远程搜索 */
    remoteMethod(val) {
      this.loading = true;
      const { userName } = JSON.parse(Storage.l.get('userInfo'));
      const params = {
        pageNum: 1,
        pageSize: 200,
        currentLoginOrg: true,
        notInUserNameList: [userName],
        userNameOrFullName: val,
      };
      request.post('/mdm/mdmPositionController/positionAndUserPage', params, { headers: { functionCode: 'mdmPositionController_positionAndUserPage' } }).then((res) => {
        if (res.success) {
          console.log(123);
          this.options = res.result.data || [];
          this.loading = false;
        }
      });
    },
    /** @desc 搜索清楚 */
    getClear() {
      this.remoteMethod();
    },
    /** @desc 搜索选择 */
    getChange(e) {
      const optionsIndex = this.options.findIndex((val) => val.id === e);
      if (optionsIndex > -1) {
        this.tableLoading = true;
        const item = this.options[optionsIndex];
        const obj = this.list.find((x) => x.id === e);
        if (!obj) {
          this.list.push(item);
        }
        this.tableLoading = false;
      }
    },
    /** @desc 点击关闭 */
    dialog_btnClose() {
      this.$emit('closeSelectModal');
    },
    /** @desc 点击删除按钮 */
    delTable(index) {
      this.list.splice(index, 1);
    },
    /** @desc 选择事件 */
    selectEvent({ records }) {
      this.selectList = records;
    },
    /** @desc 点击提交按钮 */
    dialog_btnSubmit() {
      if (!this.selectList || this.selectList.length <= 0) {
        this.$message.warning('请至少选择一条数据');
        return false;
      }
      this.$emit('getSelectPeople', this.selectList);
    },
  },
};
</script>
<style lang="less" scoped>
  .table-body{
    padding-top: 16px;
  }
</style>
