<template>
  <!-- 列表 -->
  <div class="summary-list" v-infinite-scroll="loadMore" infinite-scroll-disabled="scrollDisabled">
    <template v-for="(item, index) in list">
      <div class="summary-list-item" :key="index">
        <div class="item-top">
          <div class="item-top-left">
            <el-image class="item-head" :src="item.photo"></el-image>
            <div class="item-name">{{item.createName}}-{{item.createPosName}}</div>
          </div>
          <div class="item-top-right">{{lectureType[item.lectureType]}}</div>
        </div>
        <div class="item-center">
          <div class="item-content">
            <div v-if="item.summary">
              <span v-if="item.lectureType==='DAILY'">今日工作总结：</span>
              <span v-else-if="item.lectureType==='WEEKLY'">本周工作总结：</span>
              <span v-else-if="item.lectureType==='MONTHLY'">本月工作总结：</span>
              <span v-else-if="item.lectureType==='EXPERIENCE'">心得：</span>
              <span>{{item.summary}}</span>
            </div>
            <div v-if="item.plan">
              <span v-if="item.lectureType==='DAILY'">明日工作计划：</span>
              <span v-else-if="item.lectureType==='WEEKLY'">下周工作计划：</span>
              <span v-else-if="item.lectureType==='MONTHLY'">下月工作计划：</span>
              <span>{{item.plan}}</span>
            </div>
          </div>
          <div class="item-imgs">
            <template v-for="(pItem, pIndex) in item.images||[]">
              <el-image class="item-img" :src="pItem" :preview-src-list="item.images||[]" :key="pIndex"></el-image>
            </template>
          </div>
        </div>
        <div class="item-bottom">
          <div class="item-bottom-body">
            <div class="item-bottom-left">
              <div class="item-address">
                <i class="el-icon-location-information"></i>
                <span>{{item.location}}</span>
              </div>
              <div class="item-time">{{item.createDateAll}}</div>
            </div>
            <div class="item-tools">
              <el-button type="text" @click="tapTool('parent','showComments', index, item)" v-if="item.commentSumCount">{{item.showComments&&!item.loading?'收起评论':'查看评论'}}</el-button>
              <div class="item-tools-zan" @click="tapTool('parent','liked', index, item)">
                <img class="icon-zan" src="../../../../assets/image/zan2.png" v-if="item.liked==='Y'" />
                <img class="icon-zan" src="../../../../assets/image/zan1.png" v-else />
                <span v-if="item.likeSumCount&&item.likeSumCount>0">{{item.likeSumCount}}</span>
              </div>
              <div class="item-tools-comment" @click="tapTool('parent','showAddComment', index, item)">
                <i class="el-icon-s-comment"></i>
                <span v-if="item.commentSumCount&&item.commentSumCount>0">{{item.commentSumCount}}</span>
              </div>
            </div>
          </div>
          <CommentAdd :item="item" :index="index" @sendComment="sendComment" v-if="item.showAddComment" />
        </div>
        <!-- 评论 -->
        <div class="item-comment" v-if="item.showComments">
          <template v-for="(pItem, pIndex) in item.comments">
            <div class="comment-item" :key="pIndex">
              <div class="comment-center">
                <el-image class="comment-head" :src="pItem.photo"></el-image>
                <div>
                  <div class="comment-name">{{pItem.createName}}-{{pItem.createPosName}}</div>
                  <div class="comment-content">{{pItem.comment}}</div>
                </div>
              </div>
              <div class="comment-bottom">
                <div class="comment-bottom-body">
                  <div class="comment-time">{{pItem.createDateAll}}</div>
                  <div class="item-tools">
                    <el-button type="text" @click="tapTool('child','showChildComments', index, pItem, pIndex)" v-if="pItem.commentSumCount">{{pItem.showChildComments&&!pItem.loading?'收起回复':'查看回复'}}</el-button>
                    <div class="item-tools-zan" @click="tapTool('child','liked', index, pItem, pIndex)">
                      <img class="icon-zan" src="../../../../assets/image/zan2.png" v-if="pItem.liked==='Y'" />
                      <img class="icon-zan" src="../../../../assets/image/zan1.png" v-else />
                      <span v-if="pItem.likeSumCount&&pItem.likeSumCount>0">{{pItem.likeSumCount}}</span>
                    </div>
                    <div class="item-tools-comment" @click="tapTool('child', 'showAddComment', index, pItem, pIndex)">
                      <i class="el-icon-s-comment"></i>
                      <span v-if="pItem.commentSumCount&&pItem.commentSumCount>0">{{pItem.commentSumCount}}</span>
                    </div>
                  </div>
                </div>
                <CommentAdd :item="pItem" :index="index" :pIndex="pIndex" @sendComment="sendComment" v-if="pItem.showAddComment" />
              </div>
              <!-- 评论回复 -->
              <div class="item-comment" v-if="pItem.showChildComments">
                <template v-for="(cItem, cIndex) in pItem.comments">
                  <div class="comment-item" :key="cIndex">
                    <div class="comment-center">
                      <el-image class="comment-head" :src="cItem.photo"></el-image>
                      <div>
                        <div class="comment-name">{{cItem.createName}}-{{cItem.createPosName}}</div>
                        <div class="comment-content">{{cItem.comment}}</div>
                      </div>
                    </div>
                    <div class="comment-bottom">
                      <div class="comment-bottom-body">
                        <div class="comment-time">{{cItem.createDateAll}}</div>
                        <div class="item-tools">
                          <div class="item-tools-zan" @click="tapTool('grandson', 'liked', index, cItem, pIndex, cIndex)">
                            <img class="icon-zan" src="../../../../assets/image/zan2.png" v-if="cItem.liked==='Y'" />
                            <img class="icon-zan" src="../../../../assets/image/zan1.png" v-else />
                            <span v-if="cItem.likeSumCount&&cItem.likeSumCount>0">{{cItem.likeSumCount}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="comment-more-btn" v-if="pItem.pageNum*pageSize<pItem.pageTotal">
                  <el-button type="text" @click="getMoreComment(index, pIndex)">查看更多评论</el-button>
                </div>
                <template v-if="pItem.showChildComments">
                  <div v-if="pItem.loading">加载中...</div>
                  <div v-else-if="!pItem.comments||pItem.comments.length<=0">暂无数据</div>
                </template>
              </div>
            </div>
          </template>
          <div class="comment-more-btn" v-if="item.pageNum*pageSize<item.pageTotal">
            <el-button type="text" @click="getMoreComment(index, -1)">查看更多评论</el-button>
          </div>
          <template v-if="item.showComments">
            <div v-if="item.loading">加载中...</div>
            <div v-else-if="!item.comments||item.comments.length<=0">暂无数据</div>
          </template>
        </div>
      </div>
    </template>
    <div v-if="loading">加载中...</div>
    <div v-else-if="noMore">没有更多了</div>
    <div v-else-if="!hasData">暂无数据</div>
  </div>
</template>
<script>
import CommentAdd from './comment_add.vue';
import request from '../../../../../../utils/request';

export default {
  name: 'summary_list',
  components: {
    CommentAdd,
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasData: {
      type: Boolean,
      default: false,
    },
    noMore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lectureType: {
        // DAILY: '日报',
        // WEEKLY: '周报',
        // MONTHLY: '月报',
        // EXPERIENCE: '心得',
      },
      pageSize: 10,
    };
  },
  computed: {
    scrollDisabled() {
      return this.loading || this.noMore;
    },
  },
  created() {
    this.getDictCode('lecture_type');
  },
  methods: {
    /** @desc 加载更多 */
    loadMore() {
      this.$emit('loadMore');
    },
    /** @desc 获取数据字典数据 */
    getDictCode(dictCode) {
      request.post('/mdm/mdmdictdata/list', { dictTypeCode: dictCode }).then((res) => {
        if (res.result && res.result.length > 0) {
          res.result.map((v) => {
            this.lectureType[v.dictCode] = v.dictValue;
            return v;
          });
        }
      });
    },
    /** @desc 点击工具按钮 */
    tapTool(type, key, index, val, pIndex, cIndex) {
      console.log(type, key, index, val, pIndex, cIndex);
      let str = this.list[index];
      if (type === 'parent') {
        str = this.list[index];
      } else if (type === 'child') {
        str = this.list[index].comments[pIndex];
      } else if (type === 'grandson') {
        str = this.list[index].comments[pIndex].comments[cIndex];
      }
      if (key === 'showComments') {
        this.$set(str, key, !val[key]);
        if (val[key]) {
          this.$set(str, 'comments', []);
          this.$set(str, 'pageNum', 1);
          this.getCommentList(index, -1);
        }
      } else if (key === 'showChildComments') {
        if (!val[key]) {
          this.$set(str, 'comments', []);
          this.$set(str, 'pageNum', 1);
          this.getCommentList(index, pIndex);
        }
        this.$set(str, key, !val[key]);
      } else if (key === 'liked') {
        if (val.liked === 'Y') {
          this.$confirm('是否取消点赞？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.liked(str, 'N', val);
          });
        } else {
          this.liked(str, 'Y', val);
        }
      } else {
        this.$set(str, key, !val[key]);
      }
      this.$forceUpdate();
    },
    /** @desc 点赞 */
    liked(str, liked, val) {
      request.post('/sfa/sfa-work-summary-like-web/save', { businessId: val.id }).then((res) => {
        if (!res.success) return false;
        if (liked === 'N') {
          this.$message.success('取消点赞成功！');
          this.$set(str, 'liked', liked);
          this.$set(str, 'likeSumCount', Number(val.likeSumCount) - 1);
        } else {
          this.$message.success('点赞成功！');
          this.$set(str, 'liked', liked);
          this.$set(str, 'likeSumCount', Number(val.likeSumCount) + 1);
        }
        this.$forceUpdate();
      });
    },
    /** @desc 评论发送 */
    sendComment(val) {
      const { index, pIndex } = val;
      this.list[index].comments = [];
      this.list[index].pageNum = 1;
      this.getCommentList(index, -1);
    },
    /** @desc 选择人员弹窗 */
    showSelectModal(key, index, pIndex) {
      this.$emit('showSelectModal', { key, index, pIndex });
    },
    /** @desc 删除@ */
    delAt(val, key, index, pIndex) {
      this.$emit('delAt', {
        val, key, index, pIndex,
      });
    },
    /** @desc 获取更多评论列表 */
    getMoreComment(index, pIndex) {
      const pageNum = this.list[index].pageNum + 1;
      this.$set(this.list[index], 'pageNum', pageNum);
      this.getCommentList(index, pIndex);
    },
    /** @desc 获取评论列表 */
    getCommentList(index, pIndex) {
      let str = this.list[index];
      if (pIndex < 0) {
        str = this.list[index];
      } else {
        str = this.list[index].comments[pIndex];
      }
      const params = {
        businessId: str.id,
        pageNum: str.pageNum || 1,
        pageSize: this.pageSize,
      };
      this.$set(str, 'loading', true);
      request.post('/sfa/sfa-work-summary-comment-web/list', params).then((res) => {
        if (!res.success) return false;
        let list = str.comments || [];
        list = list.concat(res.result.data || []);
        this.$set(str, 'comments', list);
        this.$set(str, 'pageTotal', res.result.count);
        this.$set(str, 'loading', false);
      });
    },
  },
};
</script>
<style lang="less" scoped>
  // 列表数据
  .summary-list{
    font-size: 14px;
    &-item{
      padding: 10px 20px;
      margin-bottom: 10px;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
      // 顶部头像名称
      .item-top{
        display: flex;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(221, 221, 221, 0.4);
        &-left{
          flex: 1;
          display: flex;
          align-items: center;
        }
        .item-head{
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .item-name{
          font-size: 14px;
          font-weight: 500;
        }
      }
      // 内容
      .item-center{
        padding-left: 60px;
        .item-content{
          padding: 10px 0;
        }
        .item-imgs{
          display: flex;
          flex-wrap: wrap;
          .item-img{
            width: 80px;
            height: 80px;
            margin: 0 10px 10px 0;
          }
        }
      }
      // 底部
      .item-bottom{
        padding: 10px 0 10px 60px;
        .item-bottom-body{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .item-bottom-left{
            display: flex;
            align-items: center;
            .item-address{
              color: #999999;
              padding-right: 20px;
              i{
                color: #409eff;
                padding-right: 4px;
              }
            }
            .item-time{
              color: #999999;
            }
          }
        }
      }
      // 评论
      .item-comment{
        border-top: 1px solid rgba(221, 221, 221, 0.4);
        padding-top: 10px;
        padding-left: 60px;
        .comment-item{
          padding: 10px 0;
          border-bottom: 1px solid rgba(221, 221, 221, 0.4);
          .comment-center{
            display: flex;
            font-weight: 500;
            .comment-head{
              width: 50px;
              height: 50px;
              border-radius: 50%;
              margin-right: 10px;
            }
            .comment-name{
              padding-bottom: 6px;
            }
          }
          .comment-bottom{
            padding: 10px 0 10px 60px;
            .comment-bottom-body{
              display: flex;
              justify-content: space-between;
              .comment-time{
                color: #999999;
              }
            }
          }
          .comment-more-btn{
            display: flex;
            justify-content: flex-end;
          }
        }
      }
      // 右侧点赞跟评论样式
      .item-tools{
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        div{
          display: flex;
          align-items: center;
        }
        .item-tools-zan{
          padding: 0 20px;
          cursor: pointer;
        }
        .item-tools-comment{
          cursor: pointer;
        }
        span{
          padding-left: 4px;
        }
        .icon-zan{
          width: 20px;
          height: 20px;
        }
        .el-icon-s-comment{
          font-size: 20px;
          color: #409eff;
        }
      }
    }
  }
</style>
