<template>
  <!-- 左侧菜单 -->
  <div class="summary-menu">
    <template v-for="(item, index) in menuList">
      <div :key="index">
        <div class="menu-item" :class="mIndex===index?'menu-active':''" @click="clickMenu(index)">
          <i :class="item.icon">{{item.icon==='@'?'@':''}}</i>
          <span>{{item.text}}</span>
        </div>
        <div class="menu-line" v-if="index===2"></div>
      </div>
    </template>
  </div>
</template>
<script>
import request from '../../../../../../utils/request';

export default {
  name: 'summary_menu',
  data() {
    return {
      // 左侧菜单
      menuList: [
        { text: '首页', code: '', icon: 'el-icon-s-home' },
        { text: '我的', code: 'AT', icon: '@' },
        { text: '评论', code: 'COMMENT', icon: 'el-icon-chat-square' },
        { text: '我自己的', code: 'SELF', icon: 'el-icon-user' },
        { text: '直属下级', code: 'POSITION', icon: 'iconfont icon01zhushuju_zuzhiguanli' },
      ],
      mIndex: 0,
    };
  },
  created() {

  },
  methods: {
    /** @desc 点击左侧菜单 */
    clickMenu(index) {
      this.mIndex = index;
      this.$emit('changeMenu', this.menuList[index]);
    },
  },
};
</script>
<style lang="less" scoped>
  // 左侧菜单
  .summary-menu{
    width: 140px;
    padding: 0 10px;
    background-color: #FFFFFF;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    .menu-active{
      color: #409eff !important;
    }
    .menu-item{
      line-height: 40px;
      color: #999;
      &:hover{
        cursor: pointer
      }
      i{
        font-size: 14px;
      }
      span{
        padding-left: 6px;
        font-size: 14px;
      }
    }
    .menu-line{
      width: 100%;
      height: 1px;
      background-color: #D6D6D6;
      margin: 8px 0;
    }
  }
</style>
