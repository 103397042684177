var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    ref: "contentEdit",
    staticClass: "contentEdit",
    style: _vm.styles,
    attrs: { contentEditable: "", placeholder: _vm.placeholder },
    domProps: { innerHTML: _vm._s(_vm.html) },
    on: { focus: _vm.focus, blur: _vm.blur },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }