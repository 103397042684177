<template>
  <div ref="contentEdit" class="contentEdit" :style="styles" contentEditable :placeholder="placeholder" @focus="focus" @blur="blur" v-html="html"></div>
</template>
<script>
export default {
  name: 'content_edit',
  props: {
    styles: {
      type: String,
      default: '',
    },
    isClear: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    selectPeoples: {
      type: Array,
      default() {
        return [];
      },
    },
    addText: {
      type: String,
      default: '',
    },
  },
  watch: {
    isClear: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.html = '';
        }
      },
    },
    selectPeoples: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.length > 0) {
          let atStr = '';
          val.map((v) => {
            atStr = `${atStr}<span class="blue" contentEditable="false">@${v.fullName}&nbsp;</span>`;
            return v;
          });
          this.html += atStr;
          this.$nextTick(() => {
            this.$refs.contentEdit.focus();
          });
        }
      },
    },
    addText: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          const atStr = `<span class="blue" contentEditable="false">${val}</span>`;
          this.html += atStr;
          this.$nextTick(() => {
            this.$refs.contentEdit.focus();
          });
        }
      },
    },
  },
  data() {
    return {
      html: '',
    };
  },
  methods: {
    /** @desc 光标移到最后 */
    keepLastIndex(obj) {
      if (window.getSelection) { // ie11 10 9 ff safari
        obj.focus(); // 解决ff不获取焦点无法定位问题
        const range = window.getSelection(); // 创建range
        range.selectAllChildren(obj); // range 选择obj下所有子内容
        range.collapseToEnd(); // 光标移至最后
      } else if (document.selection) { // ie10 9 8 7 6 5
        const range = document.selection.createRange(); // 创建选择对象
        range.moveToElementText(obj); // range定位到obj
        range.collapse(false); // 光标移至最后
        range.select();
      }
    },
    /** @desc 聚焦事件 */
    focus(e) {
      this.keepLastIndex(e.target);
    },
    /** @desc 失去焦点事件 */
    blur(e) {
      this.html = e.target.innerHTML;
      const text = e.target.innerText;
      this.$emit('getvalue', text);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .blue{
  color: #1890ff !important;
}
/deep/ .placeholder{
  color: #999999;
}
.contentEdit{
  width: 100%;
  height: 100%;
  padding: 5px 15px;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  overflow-y: scroll;
  box-sizing: border-box;
}
[contenteditable]:focus{
  // outline: 1px solid #1890ff;
  outline: none;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #1890ff;
}
.contentEdit:empty:before{
    content: attr(placeholder);
    color: #999999;
}
.contentEdit:focus:before{
    content: none;
}
</style>
