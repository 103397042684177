<template>
  <div class="comment-add">
    <div class="comment-center">
      <div class="textarea">
        <ContentEdit styles="padding-bottom: 24px;" :isClear="isClear" placeholder="请输入评论内容" :selectPeoples="showSelectPeoples" @getvalue="(val)=>getvalue(val, 'comment')"></ContentEdit>
        <span class="at" @click="showSelectModal">@</span>
      </div>
    </div>
    <el-button type="primary" @click="send">发送</el-button>
    <!-- 选择人员弹窗 -->
    <SelectPeople :showModal="showModal" @closeSelectModal="closeSelectModal" @getSelectPeople="getSelectPeople" />
  </div>
</template>
<script>
import * as utils from '../../../../../../utils/index';
import request from '../../../../../../utils/request';
import ContentEdit from './content_edit.vue';
import SelectPeople from './select_people.vue';

export default {
  name: 'comment_add',
  components: {
    ContentEdit,
    SelectPeople,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default() {
        return -1;
      },
    },
    pIndex: {
      type: Number,
      defaule() {
        return -1;
      },
    },
  },
  data() {
    return {
      comment: '',
      isClear: false,
      showModal: false,
      selectPeoples: [],
      showSelectPeoples: [],
    };
  },
  methods: {
    /** @desc 获取输入框的值 */
    getvalue(val, key) {
      this[key] = val;
    },
    /** @desc 点击发送按钮 */
    send() {
      const params = {
        businessId: this.item.id,
        comment: this.comment,
        atColleagueCode: [],
      };
      const list = this.pick(this.comment);
      params.atColleagueCode = list.map((v) => (v.userName));
      request.post('/sfa/sfa-work-summary-comment-web/save', params).then((res) => {
        if (!res.success) return false;
        this.isClear = true;
        this.$emit('sendComment', { index: this.index, pIndex: this.pIndex });
      });
    },
    /** @desc 点击@ 按钮 */
    showSelectModal() {
      // this.$emit('showSelectModal');
      this.showModal = true;
    },
    /** @desc 关闭选择人员弹窗 */
    closeSelectModal() {
      this.showModal = false;
    },
    /** @desc 关闭选择人员弹窗提交按钮 */
    getSelectPeople(val) {
      this.showSelectPeoples = val;
      this.selectPeoples = utils.uniqueArr(this.selectPeoples, val, 'userName');
      this.closeSelectModal();
    },
    /** @desc 获取最终的@ 列表 */
    pick(str) {
      const reg = /@([^\s]*?)\s+/g;
      const list = str.match(reg) || [];
      const newList = [];
      list.map((item) => {
        let v = item;
        v = v.substr(1);
        v = v.substr(0, v.length - 1);
        this.selectPeoples.map((m) => {
          if (m.fullName === v) {
            newList.push(m);
          }
          return m;
        });
        return v;
      });
      return newList;
    },
  },
};
</script>
<style lang="less" scoped>
  /deep/ .el-textarea__inner{
    border: none;
  }
  .comment-add{
    padding: 10px;
    margin-top: 10px;
    display: flex;
    align-items: flex-end;
    background-color: #F2F2F2;
    .comment-center{
      flex: 1;
      margin-right: 15px;
      display: flex;
      flex-direction: column;
      background-color: #FFFFFF;
      // border: 1px solid #D6D6D6;
      border-radius: 4px;
      .textarea{
        width: 100%;
        height: 100px;
        position: relative;
        .at{
          position: absolute;
          bottom: 1px;
          left: 15px;
          z-index: 10;
          font-size: 16px;
          line-height: 20px;
          color: #999999;
          padding-bottom: 3px;
          background-color: #FFFFFF;
          cursor: pointer;
        }
      }
      .comment-select{
        padding-left: 15px;
        padding-bottom: 10px;
        display: flex;
        span{
          font-size: 16px;
          line-height: 20px;
          color: #999999;
          cursor: pointer;
        }
        .atList{
          flex: 1;
          padding-left: 20px;
          display: flex;
          flex-wrap: wrap;
          .blue{
            position: relative;
            span{
              color: #1890ff;
              padding-right: 4px;
              font-size: 12px;
              line-height: 20px;
            }
            .blue-shadow{
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              z-index: 10;
              background-color: rgba(0,0,0,0.2);
              text-align: center;
              line-height: 20px;
              font-size: 16px;
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }
</style>
