var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "summary-menu" },
    [
      _vm._l(_vm.menuList, function (item, index) {
        return [
          _c("div", { key: index }, [
            _c(
              "div",
              {
                staticClass: "menu-item",
                class: _vm.mIndex === index ? "menu-active" : "",
                on: {
                  click: function ($event) {
                    return _vm.clickMenu(index)
                  },
                },
              },
              [
                _c("i", { class: item.icon }, [
                  _vm._v(_vm._s(item.icon === "@" ? "@" : "")),
                ]),
                _c("span", [_vm._v(_vm._s(item.text))]),
              ]
            ),
            index === 2 ? _c("div", { staticClass: "menu-line" }) : _vm._e(),
          ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }