var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comment-add" },
    [
      _c("div", { staticClass: "comment-center" }, [
        _c(
          "div",
          { staticClass: "textarea" },
          [
            _c("ContentEdit", {
              attrs: {
                styles: "padding-bottom: 24px;",
                isClear: _vm.isClear,
                placeholder: "请输入评论内容",
                selectPeoples: _vm.showSelectPeoples,
              },
              on: {
                getvalue: function (val) {
                  return _vm.getvalue(val, "comment")
                },
              },
            }),
            _c(
              "span",
              { staticClass: "at", on: { click: _vm.showSelectModal } },
              [_vm._v("@")]
            ),
          ],
          1
        ),
      ]),
      _c("el-button", { attrs: { type: "primary" }, on: { click: _vm.send } }, [
        _vm._v("发送"),
      ]),
      _c("SelectPeople", {
        attrs: { showModal: _vm.showModal },
        on: {
          closeSelectModal: _vm.closeSelectModal,
          getSelectPeople: _vm.getSelectPeople,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }