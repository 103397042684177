var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "summary-add" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "summary-tabs",
          on: { "tab-click": _vm.tabsClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _vm._l(_vm.tabsList, function (item, index) {
            return [
              _c("el-tab-pane", {
                key: index,
                attrs: { label: item.text, name: item.name },
              }),
            ]
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "textarea is_required" },
        [
          _c("ContentEdit", {
            attrs: {
              isClear: _vm.isClear,
              placeholder: _vm.tabsList[_vm.tIndex].placeholder1,
              selectPeoples: _vm.showSelectPeoples,
              addText: _vm.addText,
            },
            on: {
              getvalue: function (val) {
                return _vm.getvalue(val, "summary")
              },
            },
          }),
        ],
        1
      ),
      _vm.activeName !== "EXPERIENCE"
        ? _c(
            "div",
            { staticClass: "textarea" },
            [
              _c("ContentEdit", {
                attrs: {
                  isClear: _vm.isClear,
                  placeholder: _vm.tabsList[_vm.tIndex].placeholder2,
                },
                on: {
                  getvalue: function (val) {
                    return _vm.getvalue(val, "plan")
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "images-container" },
        _vm._l(_vm.fileList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "images-item",
              on: {
                mouseover: function ($event) {
                  return _vm.mouseOverOrLeave(index, false)
                },
                mouseleave: function ($event) {
                  return _vm.mouseOverOrLeave(index, true)
                },
              },
            },
            [
              _c("el-image", {
                staticClass: "images",
                attrs: { src: item.url },
              }),
              item.showShadow
                ? _c("div", { staticClass: "images-shadow" }, [
                    _c("i", {
                      staticClass: "el-icon-zoom-in",
                      on: {
                        click: function ($event) {
                          return _vm.previewImage(item.url)
                        },
                      },
                    }),
                    _c("i", {
                      staticClass: "el-icon-delete",
                      on: {
                        click: function ($event) {
                          return _vm.delImage(index)
                        },
                      },
                    }),
                  ])
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
      _c("div", { staticClass: "summary-add-button" }, [
        _c(
          "div",
          { staticClass: "summary-add-button-left" },
          [
            _c(
              "i",
              {
                on: {
                  click: function ($event) {
                    return _vm.showSelectModal(false)
                  },
                },
              },
              [_vm._v("@")]
            ),
            _c(
              "el-upload",
              {
                staticClass: "upload-demo",
                attrs: {
                  action: "/upload/uploadController/uploadOssList",
                  headers: { loginUserToken: _vm.Storage.l.get("TokenKey") },
                  "list-type": "picture",
                  "show-file-list": false,
                  accept: ".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PNG,.GIF",
                  limit: _vm.limit,
                  "file-list": _vm.fileList,
                  "before-upload": _vm.beforeUpload,
                  "on-progress": _vm.handleProgress,
                  "on-success": _vm.handleSuccess,
                  "on-exceed": _vm.handleExceed,
                },
              },
              [_c("i", { staticClass: "el-icon-picture-outline" })]
            ),
            _vm.tabsList[_vm.tIndex].key
              ? [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.getVisitNum },
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v(_vm._s(_vm.tabsList[_vm.tIndex].key) + "拜访数"),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.getOrderNum },
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v(_vm._s(_vm.tabsList[_vm.tIndex].key) + "订单数"),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.getCustomerNum },
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v(
                        _vm._s(_vm.tabsList[_vm.tIndex].key) + "新增客户数"
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "summary-add-button-right" },
          [
            _c(
              "el-button",
              {
                staticClass: "summary-add-button-btn",
                attrs: { type: "primary" },
                on: { click: _vm.send },
              },
              [_vm._v("发送")]
            ),
          ],
          1
        ),
      ]),
      _c("SelectPeople", {
        attrs: { showModal: _vm.showModal, selectLists: _vm.selectList },
        on: {
          closeSelectModal: _vm.closeSelectModal,
          getSelectPeople: _vm.getSelectPeople,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, "append-to-body": "" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogPreviewImage, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }